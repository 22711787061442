.tableRow:hover{
    opacity:0.8;
    cursor:pointer;
}

.songTable{
    background-image:url("../background.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    padding: 10px;
    
}

#searchBar {
    margin-top: 3%;
    margin-bottom: 3%;
}
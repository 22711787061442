.App{
    background-image:url("background.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    min-height:100vh;
    
}

.AppContainer{
    justify-content: center;
    padding: 20px;
    min-height:100vh;
}

